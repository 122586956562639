import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import baseMixin from './utils/commonmethods.js'
import VueParticles from 'vue-particles'
import installElementPlus from './plugins/element'

const app = createApp(App);
installElementPlus(app)
app.config.globalProperties.$onLaunched = new Promise(resolve => {
    app.config.globalProperties.$isResolve = resolve;
})

app.use(store);
app.use(router);
app.use(VueParticles);
app.mixin(baseMixin);
app.mount('#app')

