import {post,get} from '../utils/request.js'

//登录
export const login = params =>post('/api/user/login',params)
//注册
export const register = params =>post('/api/user/do_register',params)
//用户信息
export const getUserinfo = params =>get('/api/my')
//首页数据
export const getIndexdata = params =>get('/api/index/index',params)
//用户充值
export const recharge = params =>post('/api/pay/gagapay',params)
//系统文章接口
export const article = params =>get('/api/my/detail',params)
//用户提现
export const withdraw = params =>post('/api/ctrl/do_deposit',params)
//用户提现
export const withdrawLog = params =>post('/api/ctrl/deposit_admin',params)
//抢单首页信息
export const getOrderinfo = params =>post('/api/rot_order/index',params)
//抢单
export const submitOrder = params =>post('/api/rot_order/submit_order',params)
//订单详情
export const orderInfo = params =>get('/api/order/order_info',params)
//订单列表
export const orderList = params =>get('/api/order/index',params)
//处理订单
export const doOrder = params =>post('/api/order/do_order',params)
//修改登录密码
export const setLoginpwd = params =>post('/api/ctrl/set_pwd',params)
//修改提现密码
export const setPaypwd = params =>post('/api/ctrl/set_pwd2',params)
//获取绑定银行卡信息
export const getBank = params =>get('/api/my/bind_bank',params)
//绑定银行卡
export const bindBank = params =>post('/api/my/bind_bank',params)
//收货地址信息
export const getAddress = params =>get('/api/my/edit_address',params)
//编辑收货地址信息
export const saveAddress = params =>post('/api/my/edit_address',params)
//团队管理
export const getTeam = params =>get('/api/my/team',params)
//账号明细
export const getCaiwu = params =>get('/api/my/caiwu',params)
//商品列表
export const getProductlist = params =>get('/api/index/product_list',params)
